@import url(https://fonts.googleapis.com/css2?family=Open+Sans:ital,wght@0,300;0,400;0,600;0,700;0,800;1,300;1,400;1,600;1,700;1,800&display=swap);
.no-padding {
    margin: 0px !important;
    padding: 0px !important;
}

.background-white {
    background: red !important;
}

.wrapper {
    margin-left: 20px;
    margin-right: 20px;
}

.welcome-text {
    margin-top: 30px;
    font-size: 18px;
    color: black;
    line-height: 1;
    margin-bottom: 20px;
}

.welcome-intro {
    margin-bottom: 0px;
    font-size: 28px;
    color: black;
}

.search-menu {
    margin-top: 10px;
    --border-radius: 20px;
    padding: 0px;
    background-color: transparent;
    --box-shadow: 0px 3px 10px 4px #e5e6eb;
    --color: gray;
    margin-bottom: 10px;
    --background: white !important;
}

.test {
    display: inline-block;
    height: 50px;
}

.horizontal {
    overflow-x: scroll;
    white-space: nowrap;
}

.category {
    display: inline-block;
    margin-right: 10px;
    text-align: center;
}

.category-img {
    margin: 0 auto;
    padding: 13px;
    width: 50px;
    height: 50px;
    border-radius: 100%;
    background-color: white;
}

.category:first-child {
    margin-left: 20px;
}

.category-text {
    transition: .3s;
    font-size: 16px;
    font-weight: 400;
    color: #666;
    border: 2px solid #f8f8f8;
    border-radius: 100px;
    padding: 8px 16px;
}

.active-category h5 {
    border: 2px solid black;
    font-size: 16px;
    font-weight: 400;
    color: #666;
    border-radius: 100px;
    padding: 8px 16px;
}

.category-title {
    margin-top: 30px;
    font-size: 18px;
    color: black;
}

.searchbar-input.sc-ion-searchbar-md {
    padding-top: 10px !important;
    padding-bottom: 10px !important;
    --background: white !important;
}

.searchbar-search-icon.sc-ion-searchbar-md {
    top: 14px;
}

  .menu-items {
      height: 410px;
  }

  .menu-item {
      position: relative;
      /*box-shadow: 0px 3px 10px 4px #e5e6eb;*/
      width: 250px;
      height: 350px;
      background-color: #f8f8f8;
      border-radius: 10px;
      background-size: cover !important;
      display: inline-block;
      margin-right: 20px;
  }

  .menu-img {
      padding: 30px;
      width: 100%;
      margin: 0 auto;
      padding-top: 20px;
  }

  .menu-text {
    padding: 20px;
  }

  .menu-text h4 {
      color: black;
      font-size: 13px;
      font-weight: 500;
  }

  .menu-text h5 {
      color: #656c7c;
      font-size: 12px;
      margin-top: -4px;
  }

  .menu-pricing {
      position: absolute;
      bottom: 0;
      width: 100%;
      white-space: normal;
      padding: 20px;
  }

  .dollar-sign {
      font-size: 12px;
      vertical-align: top;
  }

  .category-display .menu-item:first-of-type {
    margin-left: 20px;
    /*background-color: #black !important;*/
  }
  /*

  .category-display .menu-item:first-of-type .menu-pricing h5 {
    color: white !important;
  }

  .category-display .menu-item:first-of-type .menu-pricing .add-button  {
    --color: white !important;
    --background: #4c5564 !important;
  }
  
   .category-display .menu-item:first-of-type .menu-text h4 {
    color: white !important;
  }
  */

  .menu-pricing h5 {
    color: white;
    font-weight: 700;
    font-size: 28px;
}

  .menu-pricing .add-button {
      --border-radius: 20px;
      --padding-top: 8px;
      --padding-bottom: 8px;
      --padding-start: 20px;
      --padding-end: 20px;
      --box-shadow: none;
      height: unset !important;
      margin-top: 9px;
      --color: white;
      font-size: 15px !important;
      --background: black;
      text-transform: none;
  }

  .right-align {
      text-align: right;
  }

  .restaurant-logo {
      width: 50px;
  }
  
.center-align {
    text-align: center !important;
}

.padding-bottom {
    padding-bottom: 30px !important;
}

.search-bar {
    margin-left: 20px;
    margin-right: 20px;
    margin-top: -60px;
    background-color:white;
    padding: 10px;
    z-index: 999; /* Ensure the search bar appears above other content */
    transition: 0.3s ease; /* Add smooth transition when revealing the search bar */
}

  .search-bar-visible {
      margin-top: 0px;
    --padding-top: 50px; /* Adjust the padding to make space for the revealed search bar */
    transition: 0.3s; /* Add smooth transition when adjusting padding */
  }
:root {
    --dark-highlight: var(--primary);
    --primary: #ed6725;
}

.text-center {
    text-align: center !important;
    margin: 0 auto;
}

#map {
    margin-bottom: 30px;
    border-radius: 20px;
}

p {
    line-height: 2;
    color: gray;
    font-weight: 400;
}

#app-tab-bar {
    --background: white !important;
    border-top: 1px solid lightgray;
}

.ios-wrapper-margin {
    margin-top: 5vh !important;
}

.ios-wrapper{
    padding-top: 5vh !important;
}

.search-bar {
    visibility: hidden;
    pointer-events: none;
    opacity: 0;
}

.search-bar-visible {
    opacity: 1;
    visibility: visible;
    pointer-events: all;
    margin: 0;
    margin-top: -10px;
    margin-left: -20px;
    margin-right: -20px;
    margin-bottom: 10px;
    background-color: whitesmoke;
    --padding-top: 50px !important; /* Adjust the padding to make space for the revealed search bar */
    transition: 0.3s !important; /* Add smooth transition when adjusting padding */
}

/* Conrirmation*/

.highlight {
    color: var(--secondary) !important;
}

ion-button {
    --background-activated: unset;
}

.top-bar {
    margin-bottom: 60px;
}

.scrolled {
    background-color: white;
}

.ios-wrap .fixed-top {
    padding-top: 60px;
}
.fixed-top {
    transition: .3s;
    padding-top: 10px;
    padding-bottom: 10px;
    padding-left: 20px;
    padding-right: 20px;
    position: fixed;
    top: 0;
    left: 0;
    right: 0;
    z-index: 100;
}

.favourite-icon {
    text-align: right !important;
}

.back-arrow {
    font-size: 28px;
}

.action-button {
    margin-top: 10px;
    width: 100%;
    --border-radius: 100px;
    --padding-top: 30px;
    font-size: 16px;
    --padding-bottom: 30px;
    --background: whitesmoke;
    --color: gray;
    --box-shadow: none;
    --border: none;
}

.dark-highlight-button {
    --background: #eaeaea !important;
    box-shadow: none;;
}

.highlight-button {
    --background: var(--dark-highlight);
    color: white;
}

ion-grid {
    padding: 0px;
}

ion-row {
    padding: 0px;
}

ion-row.no-padding ion-col {
    padding: 0px;
}

.align-right {
    text-align: right !important;
}

ion-tab-bar {
    border-top: 1px solid var(--dark-highlight);
    --background: var(--darker-highlight) !important;
}

ion-tab-bar ion-icon {
    color: #38404D;
    font-size: 30px;
}

.no-padding {
    margin: 0px !important;
    padding: 0px !important;
    --padding-start: 0px !important;
    --padding-end: 0px !important;
    --padding-top: 0px !important;
    --padding-bottom: 0px !important;
}

.align-middle {
    vertical-align: middle !important;
}

.lowercase {
    text-transform: unset !important;;
}

.authentication-button {
    font-size: 14px !important;
    line-height: 1.5;
    --padding-bottom: 3px !important;
}

.wrapper {
    margin-left: 20px;
    margin-right: 20px;
}

.page-header {
    padding-top: 0px;
}

.page-header h1 {
    color: white;
    font-family: "Open Sans", sans-serif !important;
    font-weight: 900;
    letter-spacing: -3px;
    font-size: 40px;
    text-transform: uppercase;
    line-height: 1;
}

.page-header p {
    line-height: 1.5;
    font-size: 18px;
    font-weight: 500;
}

.icon-img {
    width:16px !important;
    margin-top: unset !important;
}

.margin-top-30 {
    margin-top: 30px;
}

.note {
    padding: 10px 20px;
    border-left: 2px solid var(--primary);
    background-color: whitesmoke;
}

.note p {
    padding: 0;
    margin: 0;
}

.note p a {
    color: var(--primary);
    text-decoration: none;
}

input[type="radio"] {
    margin-top: 22px;
}

/* Trending Events */

.trending-events {
    margin-top: 0px;
}

.trending-events .title h2 {
    font-size: 20px;
    vertical-align: bottom;
}

.view-all-button {
    color: var(--secondary) !important;
    font-size: 16px;
    font-weight: 500;
    vertical-align: top !important;
    line-height: 2.3;
}

.event {
    margin-bottom: 30px;
    background-color: #252A33;
    border-radius: 16px;
}

.event-image {
    border-top-right-radius: 16px;
    border-top-left-radius: 16px;
    height: 300px;
    position: relative;
}

.event-date {
    margin-bottom: 10px;
    margin-left: 20px;
    position: absolute;
    bottom: 0;
    left: 0;
    background-color: var(--secondary);
    width: 55px;
    border-radius: 16px;
    height: 55px;
}

.event-date h2 {
    text-align: center;
    color: var(--ion-background-color);
    font-weight: 900;
    font-size: 20px;
    font-family: "Open Sans", sans-serif;
    line-height: 0;
    margin-top: 18px;
}
.event-date h3 {
    text-transform: uppercase;
    font-family: "Open Sans", sans-serif;
    text-align: center;
    font-weight: 600;
    font-size: 14px;
    margin-top: 14px;
}

.event-details {
    padding: 20px;
    padding-top: 0px !important;
    padding-bottom: 10px !important;
}

.event-details h2 {
    font-weight: 500;
    font-size: 20px;
}

.event-details .highlight {
    font-size: 15px;
    padding-left: 2px;
    vertical-align: middle;
    font-weight: 400;
}

.event-details h3 {
    font-weight: 400;
    font-size: 15px;
    margin-top: 0px;
}

.event-details hr {
    border-bottom: 1px solid #38404D;
    border-color: #38404D;
    padding-top: 10px;
}

.event-details h4 {
    font-weight: 500;
    font-size: 16px;
}
.event-details .ticket-text {
    font-size: 14px;
    margin-left: 2px;
    font-weight: 400;
}

.event-details h5 {
    font-size: 14px !important;
    line-height: 1.5;
}

.event-details .view-details-button {
    --background: transparent;
    align-content: right !important;
}

/* View Event */

.view-event {
    position: relative;
    height: 100vh !important;
    min-height: 100vh !important;
    max-height: 100vh !important;
}

.view-event .event-image-background {
    height: 50vh;
    z-index: 0;
}

.view-event-details {
    /* margin-top: -70px; */
    position: absolute;
    text-align: center;
    left: 0;
    right: 0;
    bottom: 0 !important;
    padding-bottom: 100px;
}

.view-event-details .verified {
    margin-bottom: -20px;
    font-size: 20px;
    font-weight: 400;
}

.view-event-details  .view-event-artist {
    text-transform: uppercase;
    font-size: 50px;
    font-weight: 900;
    font-family: "Open Sans", sans-serif;
    letter-spacing: -2px;
}

.view-event-details .view-event-description {
    font-size: 16px;
    line-height: 1.5;
}

.view-event-details .detail-icon {
    font-size: 30px;
    margin-bottom: -15px;
}

.view-event-details h4.detail {
    color: var(--dark-highlight);
    font-size: 16px;
    font-weight: 400;
    margin-bottom: -10px;
}

.view-event-details h2.detail {
    font-family: "Open Sans", sans-serif;
    font-size: 20px;
    font-weight: 600;
}

.view-event-details .ticket-button {
    --background: var(--darker-highlight);
    width: 100% !important;
    --padding-top: 28px;
    --padding-bottom: 28px;
    font-size: 15px;
    margin-bottom: 20px;
}

.ticket-counter {
    margin-top: 20px;
}

.ticket-count {
    font-size: 20px;
    vertical-align: middle;
    line-height: 3.1;
}

.ticket-count-icon {
    margin-bottom: 5px;
    font-size: 17px;
    vertical-align: middle;
}

/* Checkout */

.inputs {
    margin-bottom: -10px;
}

.checkout-title {
    text-align: center;
    line-height: 2.2;
    margin-top: 0px;
    font-size: 20px;
}

.inputs h6 {
    font-size: 12px;
}

.inputs ion-input:focus {
    padding: 10px;
}

.inputs ion-input {
    --placeholder-opacity: .5;
    border-radius: 15px;
    padding: 10px !important;
    border: 2px solid lightgray;
}

.checkout-footer {
    z-index: 2;
    background-color: whitesmoke;
    padding-bottom: 40px;
    padding-left: 20px;
    padding-right: 20px;
    position: fixed;
    bottom: 0;
    width: 100%;
}

.fixed-wrapper {
    z-index: 1;
    padding-bottom: 150px;
}

.checkout-footer p {
    line-height: 1.5;
    font-size: 14px;
    text-align: center;
}

/* Order Confirmation */

.order-confirmation h3 {
    text-align: center;
    font-size: 16px;
    line-height: 1.5;
    font-weight: 400;
    margin-top: -10px;
}

.ticket {
    border-radius: 16px;
    margin-bottom: 10px;
}

.ticket .ticket-image {
    height: 300px;
    border-top-right-radius: 16px;
    border-top-left-radius: 16px;
    border-bottom-right-radius: -50px;
    border-bottom-left-radius: -50px;
}

.ticket .ticket-float-left {
    float:left;
    width: 40px;
    height: 40px;
    background-color: var(--ion-background-color);
    border-radius: 100%;
    margin-left: -25px;
    margin-top: -20px;
}
.ticket .ticket-float-right {
    float:right;
    width: 40px;
    height: 40px;
    background-color: var(--ion-background-color);
    border-radius: 100%;
    margin-right: -25px;
    margin-top: -20px;
}

.ticket .ticket-details {
    padding-top: 30px;
    border-bottom-right-radius: 16px;
    border-bottom-left-radius: 16px;
    height: 325px;
    background-color: var(--darker-highlight);
}

.ticket .ticket-details h2 {
    font-weight: 900;
    color: white;
    text-align: center;
    text-transform: uppercase;
    font-size: 40px;
    margin-top: -10px;
    margin-bottom: 20px;
}

.ticket .ticket-details .verified {
    text-align: center;
    margin-top: 0px;
    font-weight: 400;
    font-size: 20px;
}

.ticket .ticket-details .view-event-description {
    color: white;
    font-size: 16px;
    line-height: 1.5;
}

.ticket .ticket-details .detail-icon {
    font-size: 30px;
    margin-bottom: -15px;
}

.ticket .ticket-details h4.detail {
    color: lightgray;
    font-size: 16px;
    font-weight: 400;
    margin-bottom: -10px;
}

.ticket .ticket-details h3.detail {
    color: white;
    font-family: "Open Sans", sans-serif;
    font-size: 20px;
    font-weight: 600;
}

.ticket .ticket-stats {
    text-align: center;
}

.barcode {
    overflow: hidden;
    max-height: 100px;
    margin-top: -20px;
    margin-left: 10px;
    margin-right: 10px;
}

.barcode svg {
    width: 100%;
}

.my-tickets {
    margin-bottom: -70px;
}

.my-tickets-wrapper {
    padding-bottom: 60px;
}

.my-tickets-wrapper .title {
    text-align: left !important;
}

.top-header-secondary {
   
}

.top-header-secondary h1 {
    text-align: left !important;
}

.blank-verified {
    margin-top: 30px !important;
}

.bg-transparent {
    background-color: transparent !important;;
}

ion-spinner {
    color: white;
}

.styled-border-left {
    border-left: 2px solid var(--primary);
    padding-left: 10px;
}

.header-ios ion-toolbar:last-of-type {
    --border-width: 0px;
}

.view-menu-item {
    padding-bottom: 30px;
}

.view-menu-item h1 {
    text-align: center;
    margin-top: 30px;
    font-size: 28px;
    font-weight: 500;
    color: #1f293b;
}


.view-menu-item h2 {
    font-size: 18px;
    text-align: center;
    color: #1e283e;
    font-weight: 400;
}


.view-menu-item p {
    font-size: 16px;
    text-align: center;
    line-height: 1.5;
    color: gray;
    margin-bottom: 30px;
}

.view-menu-item h3 {
    color: #1e283e;
    font-weight: 400;
    font-size: 14px;
    text-align: center;
    margin-top: 30px;
    margin-bottom: 30px;
}

.view-menu-item img {
    width: 100%;
    margin-top: 20px;
    border-radius: 10px;
}

.view-menu-item-img {
    text-align: center;
}

.add-to-cart {
    background-color: transparent;
    border-radius: 100px;
    margin-top: 30px;
    padding-bottom: 20px;
    /* box-shadow: 0px 3px 10px 4px #e5e6eb; */
}

.add-to-cart {
    padding-left: 20px;
    padding-right: 20px;
    position: fixed;
    bottom: 0;
    width: 100%; /* Optional: Make it stretch across the entire width */
    /* Add any other styles as needed */
  }

.add-to-cart h4 {
    font-size: 22px;
    color: #1e283e;
    margin-top: -5px;
    font-weight: 600;
}

.add-to-cart h5 {
    font-weight: 500;
    color: gray;
    font-size: 14px;
}

.add-to-cart button {
    margin-top: 10px;
    background-color: var(--primary);
    color: white;
    font-weight: 600;
    border-radius: 100px;
    width: 100%;
    font-size: 16px;
    padding-top: 25px;
    padding-bottom: 25px;
    margin-bottom: 10px;
    transition: .1s;
}

.add-to-cart button:active {
    opacity: .85;
}


.back-button {
    --background: #f8f8f8;
    --color: gray;
    --padding-top:22px;
    --padding-bottom: 22px;
    --padding-start: 15px;
    --padding-end: 15px;
    --border-radius: 10px;
    --box-shadow: none;
    --background-activated: white !important;
}

.cart-button {
    --box-shadow: none;
    --background: transparent;
    --padding-top: 5px;
    --padding-bottom: 5px;
    --padding-start: 0px;
    --padding-end: 0px;
    margin: unset;
    padding: unset;
    height: unset;
    --background-activated: transparent !important;
}

.cart-icon-wrapper {
    background-color: #f8f8f8;
    color: gray;
    padding-top:12px;
    padding-bottom: 12px;
    padding-left:13px;
    padding-right: 13px;
    border-radius: 10px;
}

.cart-icon {
    color: black;
    font-size: 18px;
}

.cart-count {
    width: 20px;
    height: 20px;
    background-color: var(--primary);
    margin-left: -15px;
    margin-top: -25px;
    padding-top: 4px;
    border-radius: 100%;
    font-size: 12px;
}

.top-bar {
    padding-top: 10px;
}

.menu-item-img-wrapper {
    min-height: 50vh;
    background-size: cover !important;
}
.viewing-item-wrapper {
    padding-bottom: 120px;
}

.viewing-item-wrapper h1 {
    margin-top: 30px;
    font-weight: 700;
    font-size: 28px;
}

.viewing-item-wrapper p {
    line-height: 1.5;
    color: gray;
    font-size: 16px;
}

.align-right {
    text-align: right;
}

.add-checkbox {
    margin-top: 20px;
}

.cart-option {
    padding-bottom: 10px;
    margin-top: 0px;
    border-bottom: 1px solid rgba(0,0,0,0.2);
}

.cart-option:last-child{
    border-bottom: 1px solid transparent;
}

.cart-option h5 {
    color: #444;
}
.page-title {
    font-weight: 500 !important;
    font-size: 20px !important;
    margin-top: 16px !important;
}

.cart-count-bar {
    margin-top: 30px;
    background-color: white;
    border-radius: 20px;
    padding: 15px;
    margin-bottom: 40px;
}

.cart-count-title {
    color: black;
    font-weight: 500 !important;
    font-size: 16px !important;
    margin-top: 0px;
    margin-bottom: 0px;
}

.cart-view-icon {
    margin-top: -4px;
    font-size: 20px;
    margin-right: 8px;
    vertical-align: middle;
}

.cart-item {
    margin-top: 30px;
    padding-bottom: 30px;
    border-bottom: 1px solid lightgray;
}

.cart-item-img {
    padding: 2px;
    width: 80%;
}

.cart-item-name {
    margin-top: 0px;
    font-size: 15px;
    font-weight: 500 !important;
}

.cart-item-category {
    margin-top: 0px;
    font-size: 13px;
    color: black;
}

.cart-item-price {
    margin-top: 5px !important;
}

.cart-item-qty {
    padding: 0 !important;
    margin: 0 !important;
    text-align: center;
    font-size: 16px;
}

.cart-item-increment-button {
    margin-top: -8px;
    width: 35px;
    color: black;
    height: 35px;
    border-radius: 100%;
    background-color: transparent;
    border: 2px solid black;
    padding-top: 1px;
    font-size: 16px;
}

ion-footer {
    background-color: white;
}

.cart-footer {
    background-color: whitesmoke !important;;
    position: fixed;
    bottom: 0;
    width: 100%;
}

.cart-wrapperr {
    padding-bottom: 250px;
}

.footer-wrapper {
    padding-top: 10px;
    padding-bottom: 20px;
    padding-left: 20px;
    padding-right: 20px;
}

.footer-subtotal {
    font-size: 15px;
    font-weight: 500;
    color: black;
}

.footer-subtotal-price {
    font-size: 15px;
    font-weight: 600;
    color: black;
}

.footer-totals {
    margin-top:-10px;
}

.footer-final-pice {
    margin-top: 10px;
    border-top: 1px solid lightgray;
}

.cart-checkout-button {
    margin-top: 10px;
    width: 100%;
    --border-radius: 100px;
    --padding-top: 30px;
    font-size: 16px;
    --padding-bottom: 30px;
    --background: var(--primary);
    --color: white;
    margin-bottom: 25px;
    opacity: 1;
}

.cart-checkout-button:hover {
    opacity: 1 !important;
}

.cart-item-options {
    list-style: none;
    padding: 0;
}

.cart-item-options li {
    color: #666;
}
/* Ionic Variables and Theming. For more info, please see:
http://ionicframework.com/docs/theming/ */

ion-content::part(scroll)::-webkit-scrollbar {
  display: none !important;
}

::-webkit-scrollbar {
  display: none !important;
}

/** Ionic CSS Variables **/
:root {
  --ion-background-color: white;
  /** primary **/
  --ion-color-primary: black;
  --ion-color-primary-rgb: 150, 112, 73;
  --ion-color-primary-contrast: #ffffff;
  --ion-color-primary-contrast-rgb: 255, 255, 255;
  --ion-color-primary-shade: #967049;
  --ion-color-primary-tint: #967049;

  /** secondary **/
  --ion-color-secondary: #3dc2ff;
  --ion-color-secondary-rgb: 61, 194, 255;
  --ion-color-secondary-contrast: #ffffff;
  --ion-color-secondary-contrast-rgb: 255, 255, 255;
  --ion-color-secondary-shade: #36abe0;
  --ion-color-secondary-tint: #50c8ff;

  /** tertiary **/
  --ion-color-tertiary: #5260ff;
  --ion-color-tertiary-rgb: 82, 96, 255;
  --ion-color-tertiary-contrast: #ffffff;
  --ion-color-tertiary-contrast-rgb: 255, 255, 255;
  --ion-color-tertiary-shade: #4854e0;
  --ion-color-tertiary-tint: #6370ff;

  /** success **/
  --ion-color-success: #2dd36f;
  --ion-color-success-rgb: 45, 211, 111;
  --ion-color-success-contrast: #ffffff;
  --ion-color-success-contrast-rgb: 255, 255, 255;
  --ion-color-success-shade: #28ba62;
  --ion-color-success-tint: #42d77d;

  /** warning **/
  --ion-color-warning: #ffc409;
  --ion-color-warning-rgb: 255, 196, 9;
  --ion-color-warning-contrast: #000000;
  --ion-color-warning-contrast-rgb: 0, 0, 0;
  --ion-color-warning-shade: #e0ac08;
  --ion-color-warning-tint: #ffca22;

  /** danger **/
  --ion-color-danger: #eb445a;
  --ion-color-danger-rgb: 235, 68, 90;
  --ion-color-danger-contrast: #ffffff;
  --ion-color-danger-contrast-rgb: 255, 255, 255;
  --ion-color-danger-shade: #cf3c4f;
  --ion-color-danger-tint: #ed576b;

  /** dark **/
  --ion-color-dark: #222428;
  --ion-color-dark-rgb: 34, 36, 40;
  --ion-color-dark-contrast: #ffffff;
  --ion-color-dark-contrast-rgb: 255, 255, 255;
  --ion-color-dark-shade: #1e2023;
  --ion-color-dark-tint: #383a3e;

  /** medium **/
  --ion-color-medium: #92949c;
  --ion-color-medium-rgb: 146, 148, 156;
  --ion-color-medium-contrast: #ffffff;
  --ion-color-medium-contrast-rgb: 255, 255, 255;
  --ion-color-medium-shade: #808289;
  --ion-color-medium-tint: #9d9fa6;

  /** light **/
  --ion-color-light: #f4f5f8;
  --ion-color-light-rgb: 244, 245, 248;
  --ion-color-light-contrast: #000000;
  --ion-color-light-contrast-rgb: 0, 0, 0;
  --ion-color-light-shade: #d7d8da;
  --ion-color-light-tint: #f5f6f9;
}

/* @media (prefers-color-scheme: dark) {
  

  body {
    --ion-color-primary: #d5c6b6;
    --ion-color-primary-rgb: 213, 198, 182;
    --ion-color-primary-contrast: #000000;
    --ion-color-primary-contrast-rgb: 0, 0, 0;
    --ion-color-primary-shade: #bbaea0;
    --ion-color-primary-tint: #d9ccbd;

    --ion-color-secondary: #50c8ff;
    --ion-color-secondary-rgb: 80,200,255;
    --ion-color-secondary-contrast: #ffffff;
    --ion-color-secondary-contrast-rgb: 255,255,255;
    --ion-color-secondary-shade: #46b0e0;
    --ion-color-secondary-tint: #62ceff;

    --ion-color-tertiary: #6a64ff;
    --ion-color-tertiary-rgb: 106,100,255;
    --ion-color-tertiary-contrast: #ffffff;
    --ion-color-tertiary-contrast-rgb: 255,255,255;
    --ion-color-tertiary-shade: #5d58e0;
    --ion-color-tertiary-tint: #7974ff;

    --ion-color-success: #2fdf75;
    --ion-color-success-rgb: 47,223,117;
    --ion-color-success-contrast: #000000;
    --ion-color-success-contrast-rgb: 0,0,0;
    --ion-color-success-shade: #29c467;
    --ion-color-success-tint: #44e283;

    --ion-color-warning: #ffd534;
    --ion-color-warning-rgb: 255,213,52;
    --ion-color-warning-contrast: #000000;
    --ion-color-warning-contrast-rgb: 0,0,0;
    --ion-color-warning-shade: #e0bb2e;
    --ion-color-warning-tint: #ffd948;

    --ion-color-danger: #ff4961;
    --ion-color-danger-rgb: 255,73,97;
    --ion-color-danger-contrast: #ffffff;
    --ion-color-danger-contrast-rgb: 255,255,255;
    --ion-color-danger-shade: #e04055;
    --ion-color-danger-tint: #ff5b71;

    --ion-color-dark: #f4f5f8;
    --ion-color-dark-rgb: 244,245,248;
    --ion-color-dark-contrast: #000000;
    --ion-color-dark-contrast-rgb: 0,0,0;
    --ion-color-dark-shade: #d7d8da;
    --ion-color-dark-tint: #f5f6f9;

    --ion-color-medium: #989aa2;
    --ion-color-medium-rgb: 152,154,162;
    --ion-color-medium-contrast: #000000;
    --ion-color-medium-contrast-rgb: 0,0,0;
    --ion-color-medium-shade: #86888f;
    --ion-color-medium-tint: #a2a4ab;

    --ion-color-light: #222428;
    --ion-color-light-rgb: 34,36,40;
    --ion-color-light-contrast: #ffffff;
    --ion-color-light-contrast-rgb: 255,255,255;
    --ion-color-light-shade: #1e2023;
    --ion-color-light-tint: #383a3e;
  }

  /*

  .ios body {
    --ion-background-color: #f5f7fc;
    --ion-background-color-rgb: 0,0,0;

    --ion-text-color: #ffffff;
    --ion-text-color-rgb: 255,255,255;

    --ion-color-step-50: #0d0d0d;
    --ion-color-step-100: #1a1a1a;
    --ion-color-step-150: #262626;
    --ion-color-step-200: #333333;
    --ion-color-step-250: #404040;
    --ion-color-step-300: #4d4d4d;
    --ion-color-step-350: #595959;
    --ion-color-step-400: #666666;
    --ion-color-step-450: #737373;
    --ion-color-step-500: #808080;
    --ion-color-step-550: #8c8c8c;
    --ion-color-step-600: #999999;
    --ion-color-step-650: #a6a6a6;
    --ion-color-step-700: #b3b3b3;
    --ion-color-step-750: #bfbfbf;
    --ion-color-step-800: #cccccc;
    --ion-color-step-850: #d9d9d9;
    --ion-color-step-900: #e6e6e6;
    --ion-color-step-950: #f2f2f2;

    --ion-item-background: #000000;

    --ion-card-background: #1c1c1d;
  }

  .ios ion-modal {
    --ion-background-color: var(--ion-color-step-100);
    --ion-toolbar-background: var(--ion-color-step-150);
    --ion-toolbar-border-color: var(--ion-color-step-250);
  }


  .md body {
    --ion-background-color: #121212;
    --ion-background-color-rgb: 18,18,18;

    --ion-text-color: #ffffff;
    --ion-text-color-rgb: 255,255,255;

    --ion-border-color: #222222;

    --ion-color-step-50: #1e1e1e;
    --ion-color-step-100: #2a2a2a;
    --ion-color-step-150: #363636;
    --ion-color-step-200: #414141;
    --ion-color-step-250: #4d4d4d;
    --ion-color-step-300: #595959;
    --ion-color-step-350: #656565;
    --ion-color-step-400: #717171;
    --ion-color-step-450: #7d7d7d;
    --ion-color-step-500: #898989;
    --ion-color-step-550: #949494;
    --ion-color-step-600: #a0a0a0;
    --ion-color-step-650: #acacac;
    --ion-color-step-700: #b8b8b8;
    --ion-color-step-750: #c4c4c4;
    --ion-color-step-800: #d0d0d0;
    --ion-color-step-850: #dbdbdb;
    --ion-color-step-900: #e7e7e7;
    --ion-color-step-950: #f3f3f3;

    --ion-item-background: #1e1e1e;

    --ion-toolbar-background: #1f1f1f;

    --ion-tab-bar-background: #1f1f1f;

    --ion-card-background: #1e1e1e;
  }
} */

